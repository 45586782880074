import React, { useState, useEffect } from "react";
import "./ContactForm.css";
import { Images } from "../../assets";
import axios from "axios";
import { toast } from "react-toastify";

function ContactForm() {
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    message: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // URL fixe du backend
  const url = "https://www.cavallobianco.ch/src/components/backend/contact_form_handler.php";

  // Charger le script reCAPTCHA
  useEffect(() => {
    const loadReCaptcha = () => {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=6Lf5qhQqAAAAAMOToCH4yXPfR6EKo_PTzZNeHtAl`;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadReCaptcha();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    window.grecaptcha.ready(() => {
      window.grecaptcha.execute("6Lf5qhQqAAAAAMOToCH4yXPfR6EKo_PTzZNeHtAl", { action: "submit" }).then((token) => {
        const sanitizedData = {
          email: formData.email.trim(),
          phone: formData.phone.trim(),
          message: formData.message.trim(),
          recaptchaToken: token,
        };

        if (!sanitizedData.email || !sanitizedData.phone || !sanitizedData.message) {
          toast.error("Veuillez remplir tous les champs du formulaire.");
          setIsSubmitting(false);
          return;
        }

        axios
          .post(url, sanitizedData)
          .then((response) => {
            if (response.data.success) {
              toast.success("Formulaire envoyé avec succès.");
              setFormData({ email: "", phone: "", message: "" });
            } else {
              toast.error("Erreur lors de l'envoi du formulaire.");
            }
          })
          .catch(() => {
            toast.error("Erreur lors de l'envoi du formulaire.");
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      });
    });
  };

  return (
    <div className="contact-form-container">
      <div className="contact-form" id="contact-form">
        <h2>CONTACTEZ-NOUS</h2>
        <p>
          Nous sommes à votre disposition pour toute demande de renseignements
          ou services.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Numéro de téléphone</label>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group full-width">
            <label>Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-submit">
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Envoi..." : "Envoyer"}
            </button>
          </div>
        </form>
      </div>
      <div className="contact-image">
        <img src={Images.CONTACT_SEC_IMAGE} alt="Contact Us" />
      </div>
    </div>
  );
}

export default ContactForm;
