import React, { useState, useEffect } from "react";
import "./Sections.css";
import { Images } from "../../assets";
import pizzaImage from "../../assets/images/Pizza.png";

function Sections() {
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getImageStyle = () => {
    if (viewportWidth >= 1026 && viewportWidth <= 1143) {
      return {
        width: "950px",
        height: "600px",
        marginLeft: "-160px",
      };
    } else if (viewportWidth <= 1023) {
      return {
        display: "none",
      };
    }

    return {
      width: "1070px",
      height: "680px",
      marginLeft: "-180px",
    };
  };

  const shouldDisplayHeading = viewportWidth < 641 || viewportWidth > 1000;

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleOpenPDF = () => {
    window.open("/menu.pdf", "_blank");
  };


  return (
    <>
      <div className="flex flex-wrap justify-center items-center w-full p-0.5 box-border gap-x-1.5 gap-y-4">
        {/* Card 1: A PROPOS */}
        <div className="flex flex-col justify-center items-center w-full sm:w-[80%] lg:w-[32%] h-[350px] sm:h-[400px] md:h-[450px] bg-[#f5e2cb] overflow-hidden shadow-lg relative card card-effect"
          onClick={() => handleScrollTo("a-propos-section")}
        >
          <div className="pt-10 text-center bg-[#f5e2cb] w-full absolute top-5 left-1/2 transform -translate-x-1/2 z-10 text-[#713d11]">
            <h3 className="text-[24px] sm:text-[30px] md:text-[32px] text-[#713d11] tracking-[3px] sm:tracking-[4.5px]">
              A PROPOS
            </h3>
          </div>
          <div className="flex justify-center items-center w-full h-full relative z-1">
            <img
              src={Images.NOODLES}
              alt="Card 1"
              className="max-w-[30%] sm:max-w-[35%] md:max-w-[40%] lg:max-w-[35%] xl:max-w-[35%] max-h-[200px] sm:max-h-[250px] md:max-h-[300px] lg:max-h-[250px] object-contain mt-8 sm:mt-16 mx-auto"
            />
          </div>
        </div>

        {/* Card 2: MENU */}
        <div className="flex flex-col justify-center items-center w-full sm:w-[80%] lg:w-[32%] h-[350px] sm:h-[400px] md:h-[450px] bg-[#f5e2cb] overflow-hidden shadow-lg relative card card-effect"
          onClick={handleOpenPDF}>
          <div className="pt-10 text-center bg-[#f5e2cb] w-full absolute top-5 left-1/2 transform -translate-x-1/2 z-10 text-[#713d11]">
            <h3 className="text-[24px] sm:text-[30px] md:text-[32px] text-[#713d11] tracking-[3px] sm:tracking-[4.5px]">
              MENU
            </h3>
          </div>
          <div className="flex justify-center items-center w-full h-full relative z-1">
            <img
              src={Images.PUDDING}
              alt="Card 2"
              className="max-w-[30%] sm:max-w-[35%] md:max-w-[40%] lg:max-w-[35%] xl:max-w-[35%] max-h-[200px] sm:max-h-[250px] md:max-h-[300px] lg:max-h-[250px] object-contain mt-8 sm:mt-16 mx-auto"
            />
          </div>
        </div>

        {/* Card 3: CONTACT */}
        <div className="flex flex-col justify-center items-center w-full sm:w-[80%] lg:w-[32%] h-[350px] sm:h-[400px] md:h-[450px] bg-[#f5e2cb] overflow-hidden shadow-lg relative card card-effect"
          onClick={() => handleScrollTo("contact-form")}
        >
          <div className="pt-10 text-center bg-[#f5e2cb] w-full absolute top-5 left-1/2 transform -translate-x-1/2 z-10 text-[#713d11]">
            <h3 className="text-[24px] sm:text-[30px] md:text-[32px] text-[#713d11] tracking-[3px] sm:tracking-[4.5px]">
              CONTACT
            </h3>
          </div>
          <div className="flex justify-center items-center w-full h-full relative z-1">
            <img
              src={Images.SUSHE}
              alt="Card 3"
              className="max-w-[30%] sm:max-w-[35%] md:max-w-[40%] lg:max-w-[35%] xl:max-w-[35%] max-h-[200px] sm:max-h-[250px] md:max-h-[300px] lg:max-h-[250px] object-contain mt-8 sm:mt-16 mx-auto"
            />
          </div>
        </div>
      </div>
      <div className="relative">
        {/* First Section */}
        <div
          className="bg-[#713d11] px-6 sm:px-8 lg:px-20 py-10 sm:py-12 lg:py-16 flex flex-col justify-center lg:col-span-2"
          style={{ maxHeight: "420px" }}
        >
          <div className="lg:flex lg:justify-end">
            <div className="lg:w-1/2">
              <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-4 uppercase text-center lg:text-left text-white sm:ml-4 lg:ml-[30px] " id="a-propos-section">
                A PROPOS
              </h3>
              <p className="text-base sm:text-lg lg:text-lg leading-relaxed text-center lg:text-left text-white sm:ml-4 lg:ml-[30px]">
                Situé au cœur de Lausanne, près de la Place Chauderon, Cavallo
                Bianco vous propose une cuisine italienne authentique, alliant
                saveurs méditerranéennes et ingrédients frais. Découvrez des
                pizzas artisanales, pâtes fraîches et autres plats délicieux,
                préparés avec passion.
              </p>
              <p className="mt-4 text-base sm:text-lg lg:text-lg leading-relaxed text-center lg:text-left text-white sm:ml-4 lg:ml-[30px]">
                Réservez dès aujourd’hui et vivez la dolce vita!
              </p>
            </div>
          </div>
        </div>

        <div className="absolute left-0 top-0 lg:top-1/2 transform lg:translate-y-[-68%] z-10">
          <img
            src={pizzaImage}
            alt="Overlapping"
            style={getImageStyle()}
            className="sm:w-[180px] sm:h-[180px] md:w-[200px] md:h-[400px] lg:w-[1050px] lg:h-[900px]"
          />
        </div>

        {/* Second Section */}
        <div className="bg-[#f5e2cb] px-6 sm:px-8 lg:px-24 py-20 sm:py-15 lg:py-24 mt-[60px] lg:mt-[100px] min-h-[50vh] sm:min-h-[50vh] md:min-h-[90vh] lg:min-h-[90vh]"

        >
          <div className="lg:flex lg:justify-end">
            <div className="lg:w-1/2">
              <h3 className="text-xl sm:text-3xl lg:text-4xl font-bold text-[#713d11] text-center lg:text-left uppercase mb-3">
                HORAIRES D'OUVERTURE
              </h3>
              <p className="text-base sm:text-lg lg:text-lg leading-relaxed text-[#713d11] text-center lg:text-left mb-3 font-bold">
                LUNDI-SAMEDI <span className="ml-[105px] sm:ml-[90px] md:ml-[100px]"> 10H - 00H </span>
              </p>
              <p className="text-base sm:text-lg lg:text-lg leading-relaxed text-[#713d11] text-center lg:text-left font-bold">
                DIMANCHE <span className="ml-[140px] sm:ml-[130px]">17H - 00H</span>
              </p>

              <a
                href="https://maps.app.goo.gl/LqauUXvjYw4Zgxfy7"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center lg:justify-start text-[#713d11] text-base sm:text-lg lg:text-lg leading-relaxed space-x-2 mt-1 text-base no-underline hover:underline"
              >
                {/* SVG Location Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-5 h-5 sm:w-6 sm:h-6 text-[#713d11]"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2C7.58 2 4 5.58 4 10c0 4.55 6.07 10.12 7.36 11.22a1.5 1.5 0 0 0 1.92 0C13.93 20.12 20 14.55 20 10c0-4.42-3.58-8-8-8z"
                  />
                  <circle cx="12" cy="10" r="3" fill="none" stroke="currentColor" />
                </svg>

                <span className="mt-2 underline font-gilroy text-base sm:text-lg md:text-xl text-center md:text-left block">
                  PI. Chauderon 24, 1003 Lausanne
                </span>
              </a>

            </div>
          </div>

          <br />
          <br />

          <div
            className="lg:flex lg:justify-end relative py-2 lg:py-2"
            style={{ maxHeight: "135px" }}
          >
            <div className="lg:w-1/2">
              <h3 className="text-xl sm:text-3xl lg:text-4xl font-bold text-[#713d11] text-center lg:text-left uppercase mb-3">
                HORAIRES DE LA CUISINE
              </h3>

              <p className="text-base sm:text-lg lg:text-lg leading-relaxed text-[#713d11] text-center lg:text-left mb-3 font-bold">
                LUNDI-SAMEDI{" "}
                <span className="ml-[55px] sm:ml-[120px]">11H30-14H</span>{" "}
                <br />
                <span className="ml-[155px] sm:ml-[242px]">18H30-23H</span>
              </p>

              <p className="text-base sm:text-lg lg:text-lg leading-relaxed text-[#713d11] text-center lg:text-left mb-3 font-bold">
                DIMANCHE <span className="ml-[70px] md:ml-[140px] lg:ml-[140px]"> 18H30 - 23H </span>
              </p>

              <a
                href="https://maps.app.goo.gl/LqauUXvjYw4Zgxfy7"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center lg:justify-start text-[#713d11] text-base sm:text-lg lg:text-lg leading-relaxed space-x-2 mt-1 text-base no-underline hover:underline"
              >
                {/* SVG Location Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-5 h-5 sm:w-6 sm:h-6 text-[#713d11]"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 2C7.58 2 4 5.58 4 10c0 4.55 6.07 10.12 7.36 11.22a1.5 1.5 0 0 0 1.92 0C13.93 20.12 20 14.55 20 10c0-4.42-3.58-8-8-8z"
                  />
                  <circle cx="12" cy="10" r="3" fill="none" stroke="currentColor" />
                </svg>

                <span className="mt-2 underline font-gilroy text-base sm:text-lg md:text-xl text-center md:text-left block">
                  PI. Chauderon 24, 1003 Lausanne
                </span>
              </a>


              {/* Conditionally render the H2 heading */}
              {shouldDisplayHeading && (
                <h2
                  className="headingTwo absolute top-0 left-0 transform lg:-translate-x-0 lg:-translate-y-0 text-4xl sm:text-5xl md:text-5xl font-bold italic uppercase text-[#713d11] z-10 hidden sm:block sm:ml-4 lg:ml-[-30px] mb-10 mt-0 text-center lg:text-left tracking-wide"
                  style={{
                    letterSpacing: "0.1em",
                    fontFamily: "Gilroy, sans-serif",
                  }}
                >
                  <span className="relative mb-6 block font-bold">Mamma</span>
                  <span className="mt-6 block">Mia!</span>
                </h2>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sections;
